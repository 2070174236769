<template>
  <div class="mb-3.5 flex justify-between items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
    <app-section-title
      :title="t('extranet.finances.sections.received_payments')"
      class="mb-3 md:mb-0"
    />

    <app-button
      :label="t('breadcrumbs.common.download_pdf')"
      @click="handleDownloadPdf"
    />
  </div>

  <app-filters
    class="my-4"
    :display-filters="hasActiveFilters"
  >
    <payment-filters
      @filters-applied="handleFiltersApplied"
    />
  </app-filters>

  <div ref="appTableContainerRef">
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="paymentsLoading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />

  <!-- PDF download modal -->
  <app-overlay
    :show="loadingModalDisplayed"
    @clicked="handleLoadingClose"
  >
    <app-modal @closed="handleLoadingClose">
      <app-spinner
        class="text-theme-500 mb-2"
        size="2xl"
      />

      <p>
        {{ t('pdf.common.generating') }}
      </p>

      <p class="mb-4">
        {{ t('pdf.common.waiting') }}
      </p>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { upperCase } from 'lodash'

import {
  fetchPayments as fetchApiPayments,
  thanksPayment,
  downloadPdfPayments,
} from '@shared/http/api'
import { getAmountWithCurrency, getPayable } from '@shared/helpers/payment'
import { displayName } from '@shared/helpers/user'
import usePdf from '@shared/hooks/pdf'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import PaymentTableActions from '@extranet/components/resources/payment/PaymentTableActions.vue'
import AppFilters from '@extranet/components/ui/AppFilters.vue'
import PaymentFilters from '@extranet/components/resources/payment/PaymentFilters.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import useForm from '@shared/hooks/form/form'
import useFilters from '@shared/hooks/filters'
import useLocale from '@shared/hooks/locale'

const props = defineProps({})

const store = useStore()
const { t } = useI18n()

const { getAttributeTranslation } = useLocale()

// ---------- PAYMENTS ----------

const payments = ref([])
const paymentsLoading = ref(true)

const headers = [
  { label: t('validation.attributes.payer') },
  { label: t('validation.attributes.institution') },
  { label: t('validation.attributes.net_amount') },
  { label: t('validation.attributes.payment_service') },
  { label: t('validation.attributes.payment_type') },
  { label: t('validation.attributes.payable') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  payments?.value?.map((payment) => ({
    payer: displayName(payment.resource.relationships.payer),
    institution: getAttributeTranslation(payment.resource?.relationships?.institution?.attributes?.title),
    net_amount: getAmountWithCurrency(payment.resource.attributes.net_amount, payment.resource.attributes.currency),
    payment_service: t(`enums.PaymentServiceEnum.${upperCase(payment.resource.attributes.payment_service)}`),
    payment_type: t(`enums.PaymentTypeEnum.${upperCase(payment.resource.attributes.payment_type)}`),
    payable: getPayable(payment),
    actions: {
      component: PaymentTableActions,
      props: {
        resource: payment.resource,
        thanksCallback: () => handleThanks(payment.resource.id),
      },
    },
  }))
))

function fetchPayments(nextPage = false) {
  if (nextPage === false) {
    paymentsLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'mode': 'payee',
    'filters': allFieldsToSubmit.value,
  }

  fetchApiPayments(params)
    .then((response) => {
      const oldPayments = payments.value
      const newPayments = response.data.data?.map((payment) => ({
        resource: payment,
      })) ?? []

      if (nextPage) {
        // Add new results after the old ones
        payments.value = [...oldPayments, ...newPayments]
      } else {
        // Replace the old results with the new ones
        payments.value = newPayments
      }

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      paymentsLoading.value = false
    })
}

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const nextPageFetching = ref(false)
const links = ref([])
const page = ref(1)

function resetPage() {
  page.value = 1
}

function handleChangePage(value) {
  page.value = value
  fetchPayments()
}

// ---------- THANKS ----------

function handleThanks(value) {
  thanksPayment(value)
    .then(() => {
      resetPage()
      fetchPayments()
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('payments.thanks.success'), type: 'success' },
      )
    })
}

// ---------- FILTERS ----------

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: store.state.filters.payments,
  formName: 'paymentsIndexFiltersForm',
})

const {
  hasActiveFilters,
} = useFilters('payments')

function handleFiltersApplied() {
  resetPage()
  fetchPayments()
}

// ---------- DOWNLOAD PDF ----------

const { downloadPdf } = usePdf()
const loadingModalDisplayed = ref(false)

function handleDownloadPdf() {
  // Loading modal
  handleLoadingDisplay()

  const params = {
    'page': page.value,
    'mode': 'payee',
    'filters': allFieldsToSubmit.value,
  }

  downloadPdfPayments(params)
    .then((response) => {
      downloadPdf(response.data, 'payments')
    })
    .finally(() => {
      handleLoadingClose()
    })
}

function handleLoadingDisplay() {
  loadingModalDisplayed.value = true
}

function handleLoadingClose() {
  loadingModalDisplayed.value = false
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchPayments()
})
</script>
